.coursemodule {
    font-family: "AvenirNextLTPro-Bold";
    font-size: 0.8rem;
    font-weight: 600;
}

.coursemodulekit {
    color: #126346;
    font-family: "AvenirNextLTPro-Bold";
    font-size: 0.9rem;
    font-weight: 600;
}

.coursemoduleprev {
    color: #166479;
    font-family: "AvenirNextLTPro-Bold";
    font-size: 0.9rem;
    font-weight: 600;
}

/* subscription modal css started */
strong{
    font-size: 0.9rem;
}
.subscribeimg {
    width: 1.3rem !important;
    height: 1.3rem !important;
}
.subbuttonone {
    font-family: "AvenirNextLTPro-Regular";
    font-size: 0.9rem;
    width: 400px;
    height: 38px;
    border-radius: 4px;
    box-shadow: -3px 3px 1px 0px rgba(0, 0, 0, 0.25);
    border: none;
    background-color: #000000;
    color: #FFFFFF;
}


/* subscription modal css ended */