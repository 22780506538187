.chatscreen {
    width: 100%;
    margin-top: 5px;
    height: 100%;
}
.chatHeading{
    font-family: "RecoletaBold";
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
    animation-timing-function: ease-out;
    animation-duration: 300ms;
    color: #000000;
}
.chat-head {
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0px 2px 4px 0px #00000040;
    flex-shrink: 0;
    padding: 0 20px;
}

.chat-head-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.chat-head-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.chat-profile {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.chat-head-name {
    display: flex;
    flex-direction: column;
}

.chat-head-name>span:nth-child(1) {
    font-family: 'RecoletaMedium';
    font-weight: 600;
    font-size: 24px;
    color: #000000;
}

.chat-head-name>span:nth-child(2) {
    font-family: 'AvenirNextLTPro-Regular';
    font-weight: 600;
    font-size: 16px;
    color: #5B5B5B;
}

.chat-head-right-buttton {
    width: 122px;
    height: 38px;
    border: 1px solid #7A0000;
    border-radius: 4px;
    box-shadow: -3px 3px 1px 0px #00000040;
    font-family: 'AvenirNextLTPro-Bold';
    font-weight: 600;
    color: #7A0000;
    font-size: 16px;
    line-height: 21px;
}

.chat-mainconatiner {
    overflow-y: hidden;
    width: 100%;
    height: 100%;
    max-height: 75%;
    background-image: url(../../../Assets/chatroom-background.png);
    background-position: center;
    background-size: cover;
    overflow-y: hidden;
}

.chat-body-conatiner {
   display: flex;
   /* justify-content: center; */
   flex-direction: column;
   /* align-items: center; */
   padding-left: 20%;
   padding-right:20%;
   padding-top: 2%;
   gap: 10px;
   overflow-y: scroll;
   height: 430px;
   scrollbar-width: none;
   margin-bottom: 20px;
}


.chat-body-my-chat {
    display: inline-block;
    background-color: #C6DBD1;
    padding: 10px 15px;
    border-radius: 8px;
    max-width: 60%; /* To ensure the text wraps after a certain width */
    word-wrap: break-word;
    align-self: flex-end;
    font-family: 'AvenirNextLTPro-Regular';
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    flex-direction: column;
    position: relative;
}

.chat-body-others-chat {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 8px;
    max-width: 50%;
    word-wrap: break-word;
    background-color: #E5E5EA;
    align-self: flex-start;
    font-family: 'AvenirNextLTPro-Regular';
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    flex-direction: column;
    position: relative;
}

.chat-body-my-chat-img {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 8px;
    /* max-width: 200px;
    height: 210px; */
    background-color: #C6DBD1;
    align-self: flex-end; 
    justify-content: center;
    align-items: center;
    position: relative;
}

.chat-body-others-chat-img {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 8px;
    /* max-width: 200px;
    height: 210px; */
    background-color: #E5E5EA;
    align-self: flex-start; 
    justify-content: center;
    align-items: center;
    position: relative;
}


.message-img-conatiner {
    width: 210px;
    height: 210px;
    object-fit: cover;
    border-radius: 8px;
    position: relative;
}

.message-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.message-time-text {

    text-align: right;
    font-family: 'AvenirNextLTPro-Regular';
    font-weight: 500;
    font-size: 10px;

}
.adminmsg{
    background-color:#E5E5EA;
    max-width: 380px !important;
    max-height: 104px !important;
    border-radius: 8px;
    padding: 2%;
}
.message-time {
    text-align: left;
    font-family: 'AvenirNextLTPro-Regular';
    font-weight: 500;
    font-size: 10px;
    padding-bottom: 10px;
}

.chat-footer {
    background-color: #E7E7E7 ; 
    height: 100px;
    box-shadow: 0px -1px 4px 0px #00000040;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding-left: 300px;
    padding-right: 300px; */
    /* justify-content: space-around; */
    width: 100%;
    justify-content: center;
    gap: 10px;
}

.chat-inputbox-conatiner {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #FDFCFB;
    height: 52px;
    width: 60%;
    border-radius: 8px;
    padding-left: 15px;
    padding-right: 15px;
}

.chat-inputbox-conatiner>input {
    border: none;
    outline: none;
    width: 100%;
    font-family: 'AvenirNextLTPro-Regular';
    font-weight: 500;
    font-size: 14px;
    color: #5B5B5B;
}

.chat-attachement-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.media-icons {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.sendIcon {
    width: 28px;
    height: 28px;
    cursor: pointer;
}

.attachment-popup {
    position: absolute;
    bottom: 90px; /* Position it above the attachment icon */
    right: 20px; 
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px;
    z-index: 1000;
  }
  
  .attachment-popup ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .attachment-popup li {
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .attachment-popup li:hover {
    background-color: #f0f0f0;
  }


  .message-file-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .message-file-link {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    display: inline-block;
    max-width: 200px;  /* Set the maximum width for the filename */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;  /* Adds the ellipsis */
  }
  
  .download-link {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
  }
  
  .download-link:hover {
    background-color: #0056b3;
  }
  
  .file-icon {
    margin-right: 8px;
    /* background-image: url('/path-to-your-file-icon.svg');  */
    width: 20px;
    height: 20px;
    display: inline-block;
  }
  

@media (max-width: 500px) {

.chat-head {
    justify-content: space-between;

}

.chat-head-name>span:nth-child(1) {
    font-size: 16px;
}

.chat-head-name>span:nth-child(2) {
    font-size: 10px;
}

.chat-head-right-buttton {
    width: 77px;
    height: 28px;
    font-size: 12px;
}

.chat-body-conatiner {
    padding-left: 10px;
    padding-right: 10px;
}
.chat-inputbox-conatiner {
    width: 80%;
}
.chat-body-conatiner {
    padding-left: 10px;
    padding-right: 10px;
}
  }
