body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "AvenirNextLTPro-Regular";
  src: local("AvenirNextLTPro-Regular"),
   url("./Components/font-family/AvenirNextLTPro-Regular.ttf") format("truetype");

 }

 @font-face {
  font-family: "AvenirNextLTPro-Bold";
  src: local("AvenirNextLTPro-Bold"),
   url("./Components/font-family/AvenirNextLTPro-Bold.ttf") format("truetype");

 }
 @font-face {
  font-family: "avenir-next-medium";
  src: local("avenir-next-medium"),
   url("./Components/font-family/avenir-next-medium.ttf") format("truetype");

 }

 @font-face {
  font-family: "recoleta-regulardemo";
  src: local("recoleta-regulardemo"),
   url("./Components/font-family/recoleta-regulardemo.ttf") format("truetype");

 }

 @font-face {
  font-family: "RecoletaBold";
  src: local("RecoletaBold"),
   url("./Components/font-family/RecoletaBold.ttf") format("truetype");

 }

 @font-face {
  font-family: "RecoletaMedium";
  src: local("RecoletaMedium"),
   url("./Components/font-family/Recoleta-Medium.ttf") format("truetype");

 }
 