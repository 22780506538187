
.cpara {
    font-family: "AvenirNextLTPro-Regular";
    font-size: 0.8rem;
    font-weight: 600;
    color: #000000;
}
.ctitle{
    font-family: "RecoletaMedium";
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
}
.csub{
    font-family: "AvenirNextLTPro-Bold";
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 14px;
    text-align: left; 
    border: none !important;
    background-color: #EAEAEA;
    border-radius: 4px;
    padding: 7px 7px;
    box-shadow: -3px 3px 1px 0px #00000040;
    cursor: pointer;
}
.card:hover {
    background-color: #f3f3f3;
    transition: background-color 0.3s ease;
}
.cstart:hover{
    background-color: #eeeeee;
}
.csub:hover{
    background-color: #d9d9d9;
}
.cstart{
    border-radius: 4px;
    border: 1px 0px 0px 0px;
    border: 1px solid #005230;
    box-shadow: -3px 3px 1px 0px #00000040;
    font-family: "AvenirNextLTPro-Bold";
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 1rem;
    text-align: center;
    color: #005230; 
    padding: 6px 15px; 
    background: #FDFCFB;
}
