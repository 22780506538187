.notification-modal {
    padding: 20px;
    background-color: #F5F3EE;
    border-radius: 20px;
}
.notificationmodal-cancelbutton {
  display: flex;
  align-items: end;
}

.notificationmodal-head {
    padding: 10px;
}

.notificationmodal-notification-text {
    font-family: 'RecoletaBold';
    font-size: 32px;
    font-weight: 700;
    color: #000000;
}

.notificationmodal-notificationsetting-conatiner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.notificationmodal-notificationsetting-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}


.notificationmodal-notificationsetting-icon>span {
    font-family: 'AvenirNextLTPro-Regular';
    font-weight: 600;
    font-size: 16px;
    color: #000000;
}

.notificationmodal-body {
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
    scrollbar-width: none;
    gap: 10px;
    max-height: 400px;
}

.notificationmodal-card {
    border: 1px solid #D4D2CE;
    border-radius: 8px;
    background-color: #FDFCFB;
}

.notificationmodal-card-head {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    padding: 15px;
}

.notificationmodaal-cardhead-text {
    display: flex;
    flex-direction: column;
}

.notificationmodaal-cardhead-text>span:nth-child(1) {
    font-family: 'AvenirNextLTPro-Regular';
    font-weight: 600;
    font-size: 18px;
    color: #000000;
}

.notificationmodaal-cardhead-text>span:nth-child(2) {
    font-family: 'AvenirNextLTPro-Regular';
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}

.notificationmodal-cardhead-dot>span {
    display: inline-block; 
    width: 8px;
    height: 8px;
    border-radius: 50%; 
    background-color: #7A0000;
}

.notificationmodal-card-content {
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.notificationmodal-cardcontent-level>span:nth-child(1) {
    font-family: 'AvenirNextLTPro-Regular';
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    line-height: 18px;
}

.notificationmodal-cardcontent-level>span:nth-child(2) {
    font-family: 'AvenirNextLTPro-Regular';
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    line-height: 18px;
}

.notificationmodal-card-contentfooter-conatiner {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding-left: 15px;
    padding-bottom: 10px;
}

.notificationmodal-cardfooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.notification-Accept {
    font-family: 'AvenirNextLTPro-Regular';
    font-weight: 600;
    font-size: 14px;
    color: #005230;
    line-height: 18px;
}

.notification-reject {
    font-family: 'AvenirNextLTPro-Regular';
    font-weight: 600;
    font-size: 14px;
    color: #7A0000;
    line-height: 18px;
}

.notification-cardfooter {
    padding-left: 15px;
    padding-bottom: 10px;
}