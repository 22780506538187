.stripmodal {
    padding: 20px;
}

.stripmodal-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.stripmodal-body {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stripmodal-body>span:nth-child(1) {
    font-family: "RecoletaBold";
    font-size: 28px;
    font-weight: 600;
}

.stripmodal-body>span:nth-child(2) {
    font-family: "recoleta-regulardemo";
    font-size: 20px;
    font-weight: 500;
    padding-top: 10px;
}

.stripmodal-body>span:nth-child(3) {
    font-family: "AvenirNextLTPro-Regular";
    font-size: 16px;
    font-weight: 500;
    padding-top: 10px;
    color: #000000;
    text-align: center;
    padding-top: 10px;
}

.stripmodal-card-wrapper {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.stripmodal-crad {
    width: 100%;
    max-width: 180px;
    height: 90px;
    display: flex;
    flex-direction: column;
    background-color: #FDFCFB;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #D4D2CE;
    cursor: pointer;
}

.stripmodal-crad1 {
    
    display: flex;
   
    background-color: #FDFCFB;
    font-family: "RecoletaBold";
    border-radius: 8px;
    border: 1px solid #D4D2CE;
    cursor: pointer;
}

.stripmodal-crad>span:nth-child(1) {
    font-family: "RecoletaBold";
    font-size: 22px;
    font-weight: 700;
    color: #000000;
}

.stripmodal-crad>span:nth-child(2) {
    font-family: "RecoletaBold";
    font-size: 20px;
    font-weight: 700;
    color: #000000;
}

.stripmodal-footer {
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: center;
    text-decoration: underline;
    font-family: "AvenirNextLTPro-Regular";
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    cursor: pointer;
}

.payment-conatiner {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
}

.card-elemt-container {
    width: 100%;
}

.card-element-login {
    display: flex;
    flex-direction: column;

}

.procedtopay-button-conatiner {
    display: flex;
    justify-content: center;
    padding-top: 30px;
}

.procedtopay-button {
    background-color: #000000;
    border: none;
    border-radius: 5px;
    padding: 5px;
    font-family: "AvenirNextLTPro-Bold";
    font-size: 16px;
    color: #FDFCFB;
    width: 100%;
}



  
  .wallet-icon {
    width: 50px;
    height: 50px;
  }
  
  .wallet-balance {
    background-color: #FDFCFB;
    border-radius: 12px;
  }

  .textfam {
    font-family: "RecoletaBold";
  }
  
  .session-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .session-card {
    background: #f8f9fa;
    border: 1px solid #ddd;
    cursor: pointer;
    transition: 0.3s;
    width: 120px;
  }
  
  .session-card:hover {
    background-color: #FDFCFB;
  }
  .wallet-balance {
    background: #f8f9fa;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
  }
  
  .wallet-icon {
    width: 45px;
    height: 45px;
  }
  
  
  