.copara {
    font-family: "AvenirNextLTPro-Regular";
    font-size: 0.8rem;
    font-weight: 600;
    color: #000000;
    
}
.coline{
    border: 1px solid #D0D0D0;
    border-radius: 8px;
}
span img,dd img{
    width: 0.7rem;
    height: 0.7rem;
}
.cardindex{
  font-family: "RecoletaMedium";
  font-size: 1.2rem;
  font-weight: 700;
  color: #000000; 
}
.cotitle1{
    font-family: "RecoletaBold";
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    color: #000000;    
}
.cotitle2{
    font-family: "RecoletaMedium";
    font-size: 1.2rem;
    font-weight: 700;
    color: #000000;   
}
.cosubtitle{
    font-family: "AvenirNextLTPro-Regular";
    font-size: 1rem;
    font-weight: 700;
    color: #000000; 
    line-height: 1.1rem;  
}
.cobtn1 {
  border-radius: 5px;
  border: 1.5px solid #000000;
  box-shadow: -3px 3px 1px 0px #00000040;
  background: #F8EDDC;    
  padding: 3% 4%;
  display: flex;
  align-items: center; 
  justify-content: center;
  white-space: nowrap;
}

.cobtn2{
    max-width: 504px;
    max-height: 80px;
    border-radius: 4px;
    border: 1px solid #D0D0D0;
    margin-bottom: 4%;   
    padding: 3% 0%;
    font-family: "AvenirNextLTPro-Regular";
    font-size: 0.9rem;
    font-weight: 700;
    background-color: #F5F3EE;
}
.cocardbtn1{ 
    max-width: 166px;
    height: 33px;
    background: #EAEAEA;
    font-family: "AvenirNextLTPro-Bold";
    font-size: 0.8rem;
    font-weight: 600;
    text-align: left;
    color: #000000;
    box-shadow: -3px 3px 1px 0px #00000040;
    border-radius: 4px;
    cursor: pointer;
    padding-left: 5%;
}
.cocardbtn2:hover{
  background-color: #eeeeee;
}
.cocardbtn2{
    border-radius: 4px;
    border: 1px 0px 0px 0px;
    background: #FDFCFB;
    border: 1px solid #005230;
    font-family: "AvenirNextLTPro-Bold";
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 1rem;
    text-align: center;
    color: #005230;
    padding: 7px 7px;
    box-shadow: -3px 3px 1px 0px #00000040;
    cursor: pointer;
}
.cobooknowbutton{
    max-width: 205px !important;
    max-height: 40px !important;
    border-radius: 5px;
    background: #000000;
    font-family: "AvenirNextLTPro-Bold";
    font-size: 0.7rem;
    font-weight: 600;
    text-align: center;
    color: #FDFCFB; 
    border: none;
    padding: 10px 50px;
}
.cocardbtn1:hover{
  background-color: #d9d9d9 !important;
}
.coinput{
  max-width: 90px;
  max-height: 40px;
  font-family: "AvenirNextLTPro-Regular";
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;
  color: #000000;  
  border-radius: 4px;
  border: 1px 0px 0px 0px;
  background: #F5F3EE;
  border: 1px solid #000000;
  box-shadow: -2px 2px 1px 0px #00000040;
  outline: none;
  border: none;
  padding: 7% 2%;
  cursor: pointer;
}
/* custom-calendar.css */
.custom-calendar .MuiPickersDay-root {
    font-family: "AvenirNextLTPro-Regular";
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 26px;
    text-align: left;    
    color: #000000;
  }
  
  .custom-calendar .MuiPickersDay-root:hover {
    background-color: #E0E0E0; 
  }
  
  .custom-calendar .Mui-selected {
    background-color: #000000 !important; 
    color: #FFFFFF; 
  }
  
  .custom-calendar .MuiCalendarPicker-root {
    background-color: #F5F3EE; 
    border-radius: 8px; 
    padding: 16px; 
  }
  
  .custom-calendar .MuiPickersCalendarHeader-label {
    font-size: 18px; 
    font-weight: 600;
    color: #333333; 
  }
  
  .custom-calendar .MuiPickersArrowSwitcher-root button {
    color: #000000; 
  }
  .MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel.css-ufcxyz-MuiTypography-root-MuiDayCalendar-weekDayLabel {
    font-family: "AvenirNextLTPro-Bold";
    font-size: 0.9rem;
    font-weight: 600;
    color: #000000;

}
  .MuiPickersCalendarHeader-label{
    font-family: "AvenirNextLTPro-Bold";
    font-size: 1rem;
    font-weight: 600;
    text-align: left;    
    color: #000000;
  }
.coursekit{
  border-radius: 8px;
  background-color: #E5ECE3;
  border: 1px solid #D0D0D0;
}
.courseprev{
  border-radius: 8px;
  background-color: #E3EAEC;
  border: 1px solid #D0D0D0;  
}
@media (width: 1024px) {
    .card {
        height: none !important;
        max-width: none !important;
    }
}

.coursecompleted{
  font-family: "AvenirNextLTPro-Regular";
  font-size: 0.8rem;
  border-radius: 8px;
  border: 1px;
  background-color: #E4E4E4;
  border: 1px solid #D0D0D0 ;
  cursor: pointer;
}