body {
    background-color: #F5F3EE;
}

.headerone {
    width: 100%;
    padding: 0px 20px;
}

.containerheader {
    display: grid;
    grid-template-columns: 65% 35%;
    flex-direction: row;
}

.headerprofile {
    max-width: 189px;
    font-family: 'RecoletaBold';
    font-size: 2.8rem;
    font-weight: 700;
}

.btn-leftheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    margin-left: -157px;
    justify-content: end;
}

.btn-leftheadersub {
    font-family: "AvenirNextLTPro-Regular";
    font-size: 1rem;
    font-weight: 700;
    color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: center;
    padding-right: 20px;
    position: relative;
    text-decoration: none;
}

.btn-leftheadersub::after {

    content: "";
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 90%;
    height: 2px;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.btn-leftheadersub:hover::after {

    background-color: #000000;
}

.btn-leftheadersub123 {
    width: 115px;
    max-height: 40px;
    font-family: "AvenirNextLTPro-Regular";
    font-size: 16px;
    font-weight: 600;
    color: white;
    background-color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.modalhightandwidth {
    border-radius: 20px;
    box-shadow: 0px 6px 2px 0px #00000040;
}
.wave_img {
    display: flex;
    justify-content: center;
}

/* heading screen started */

.btn-button {
    font-family: "AvenirNextLTPro-Regular";
    font-weight: 600;
    font-size: 20px;
    border: none;
    /* margin-left: 20px; */
    background-color: #F5F3EE;
    cursor: pointer;
}

/* main started */
.mainbutton {
    border: none;
    font-family: "AvenirNextLTPro-Regular";
    font-size: 15px;
    font-weight: 600;
    padding: 6px 7px;
    line-height: 21.86px;
    text-align: center;
    color: #000000;
    border-radius: 5px;
    margin-left: 10px;
    gap: 8px;
    align-items: center;
    display: flex;
    box-shadow: -1px 2px 2px rgba(0, 0, 0, 0.3);


}

/* maind ended */



/* select sign css started */
.modal {
    margin-left: 50px;
}

.poptitle {
    font-family: 'RecoletaBold';
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    color: #000000;
    margin-top: 20px;
}

.popparagraph {
    font-family: "AvenirNextLTPro-Regular";
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    font-family: "AvenirNextLTPro-Regular";
    color: #000000;
    width: 377px;
}

.popparagraph1 {
    font-family: "AvenirNextLTPro-Regular";
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    font-family: "AvenirNextLTPro-Regular";
    color: #000000;
    width: 377px;
}

.popimage {
    height: 10rem;
    width: 10rem;
}

.popupbuttons {
    box-shadow: -3px 3px 1px 0px #00000040;
    animation-timing-function: ease-out;
    animation-duration: 300ms;
    width: 250px;
    height: 40px;
    border: none;
    font-family: "AvenirNextLTPro-Regular";
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #000000;
    border-radius: 5px;
    margin-bottom: -5px;
    border: 1.5px solid #000000
}

.googleimage {
    width: 18px;
    height: 18px;
    gap: 0px;

}

.icon_top {
    height: 16px;
    width: 16px;
    margin-top: 5px;
    margin-right: -6px;
}

/* Media Queries */


/* select sign modal css ended */

/* address css started */
.addressback {
    font-family: "AvenirNextLTPro-Regular";
    font-size: 1rem;
    font-weight: 600;
    color: #000000;
    border: none;
    background-color: #F5F3EE;
}

.addressheader {
    font-family: "RecoletaBold";
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    color: #000000;
    margin-top: 20px;
}

.addresspara {
    font-family: "AvenirNextLTPro-Regular";
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1rem;
    text-align: left;
    color: #000000;
}

.address {
    font-family: "AvenirNextLTPro-Regular";
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1rem;
    text-align: left;
    color: #000000;
}

.addressinput {
    box-shadow: -3px 3px 1px 0px #00000040;
    background-color: #EAEAEA;
    width: 367px;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 4px;
    padding-left: 10px;
}

.eyeIcon {
    width: 20px;
    height: 20px;
    margin-left: -33px;
    margin-top: -5px;
}

.addressbutton {
    box-shadow: -3px 3px 1px 0px #00000040;
    border: none;
    background-color: #000000;
    width: 160px;
    height: 35px;
    border-radius: 5px;
    font-family: "AvenirNextLTPro-Regular";
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #FDFCFB;
    border-radius: 4px;
    margin-top: 10px;
}

.addresslock {
    max-width: 380px;
    font-family: "AvenirNextLTPro-Regular";
    font-size: 0.8rem;
    font-weight: 600;
    color: #000000;
    margin-top: 62px;
    margin-left: 30px;
    display: grid;
    grid-template-columns: 10% 92%;
    line-height: 1rem;
    margin-top: 100px;
}

@media only screen and (max-width : 499px) {
    .addresslock {
        margin-right: 250px;
    }
}

/* address css ended */

.modalbackgroundcolor {
    background-color: #F5F3EE;
}

/* otp pupup css started */
.otpinput {
    max-width: 42px;
    height: 42px;
    border-radius: 4px;
    background-color: #EAEAEA;
    border: none;
    margin-right: 10px;
    outline: none;
    text-align: center;
    box-shadow: -3px 3px 1px 0px rgba(0, 0, 0, 0.25);
}

.otpheader {
    width: 520px;
    margin-top: 31px;
    font-family: 'RecoletaBold';
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    color: #000000;
    margin-bottom: 13px;
}

.change_email {
    margin-left: -6px;
    margin-top: -24px;
}

.otpenteremail {
    font-family: "AvenirNextLTPro-Regular";
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.2rem;
    text-align: left;
    color: #000000;
    margin-bottom: 5px;
    max-width: 300px !important;
}

.otpchangemail {
    font-family: "AvenirNextLTPro-Regular";
    font-size: 0.9rem;
    font-weight: 600;
    text-align: left;
    color: #000000;
    height: 30px;
    border: none;
    background-color: #F5F3EE;
    margin-bottom: 19px;
}

.otpentercode {
    font-family: "AvenirNextLTPro-Regular";
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #000000;
}

.otpbutton {
    box-shadow: -3px 3px 1px 0px #00000040;
    width: 128px;
    height: 35px;
    gap: 0px;
    border-radius: 5px;
    font-family: "AvenirNextLTPro-Regular";
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #F5F3EE;
    border: none;
    background-color: #000000;
    margin-top: 16px;
}

.otpfooter {
    font-family: "AvenirNextLTPro-Regular";
    font-size: 0.9rem;
    font-weight: 600;
    color: #000000;
    margin-left: 25px;
    cursor: pointer;
}



@media only screen and (max-width : 499px) {
    .otpfooter {
        margin-right: 300px;
        cursor: pointer;
    }
}

/* otp pupup css ended */

/* password popup css started */
.passbutton {
    box-shadow: -3px 3px 1px 0px #00000040;
    width: 160px;
    height: 35px;

    border-radius: 5px 0px 0px 0px;
    font-family: "AvenirNextLTPro-Regular";
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #F5F3EE;
    border: none;
    background-color: #000000;
    margin-top: -5px;
}

.passheader1 {
    display: flex;
    justify-content: left;
    height: 43px;
    font-family: "RecoletaBold";
    font-size: 25px;
    font-weight: 700;
    color: #000000;
    margin-top: 16px;
}

.passheader2 {
    display: flex;
    justify-content: center;
    height: 43px;
    font-family: "RecoletaBold";
    font-size: 25px;
    font-weight: 700;
    color: #000000;
    margin-top: 41px;
}

.pass {
    font-family: "AvenirNextLTPro-Bold";
    font-size: 1rem;
    font-weight: 600;
    color: #000000;
    margin-top: 20px;
}

.passpara {
    font-family: "AvenirNextLTPro-Regular";
    font-size: 0.9rem;
    font-weight: 600;
    color: #000000;
    margin-top: -6px;
    line-height: 1rem;
}

.passinput {
    box-shadow: -3px 3px 1px 0px #00000040;
    width: 368px;
    height: 44px;
    max-width: 520px;
    max-height: 52px;
    background-color: #EAEAEA;
    border: none;
    outline: none;
    border-radius: 4px;
    padding-left: 10px;
}

.passbutton {
    width: 160px;
    height: 35px;
    gap: 0px;
    border-radius: 5px;
    margin-top: 16px;
    box-shadow: -3px 3px 1px 0px #00000040;
    font-family: "AvenirNextLTPro-Regular";
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #FDFCFB;
    align-items: center;
}

.passpageno {
    font-family: "AvenirNextLTPro-Regular";
    font-size: 0.9rem;
    font-weight: 600;
    color: #000000;
    margin-top: 29px;
    cursor: pointer;
    margin-top: 120px !important;
}

/* password popup css ended */

/* name css i am used password css only */

/* amount css started */
.amounthead {
    display: flex;
    font-family: "RecoletaMedium";
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: 20px;
    justify-content: center;
}

.amountimage {
    max-width: 70px !important;
    max-height: 70px !important;
    margin-top: 15px;
}

.amount {
    font-family: "RecoletaMedium";
    font-size: 0.9rem;
    font-weight: 700;
    color: #000000;
    line-height: 1rem;
}

.amountdisplayflex {
    display: flex;
    justify-content: center;
    max-width: 300px;
    max-height: 103px;
    border-radius: 8px;
    border: 1px 0px 0px 0px;
    justify-content: space-around;
    border: 1.5px dashed rgba(0, 0, 0, 1);
    background-color: #FDFCFB;
    margin-left: 2.7rem;
}

.amountpara {
    font-family: "AvenirNextLTPro-Regular";
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 600;
    text-align: center;
    font-family: "AvenirNextLTPro-Regular";

}

.a1 {
    font-weight: 600;
}

.amountline {
    width: 420px;
    height: 0px;
    border: 2px;
    angle: 0 deg;
    border: 2px solid #18181826
}

.amount_input {
    display: flex;
    justify-content: center;
}

.amountinput {
    width: 342px;
    height: 40px;
    max-width: 400px;
    max-height: 52px;
    top: 718px;
    left: 721.06px;
    gap: 0px;
    border-radius: 8px;
    font-family: "AvenirNextLTPro-Regular";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #5B5B5B;
    border: none;
    outline: none;
}

.amountbtn {
    display: flex;
    justify-content: center;

}

.amountbutton {
    display: flex;
    justify-content: center;
}

.amountbutton {
    height: 35px;

}

.nav_names {
    margin-top: 6px;
}

@media only screen and (max-width:499px) {
    .amountdisplayflex {
        margin-left: 200px;
    }

    .amountinput {
        margin-left: 10px;
    }

    .passheader {
        margin-left: 120px;
    }
}

/* amount css ended */

/* end screen css started */
.endhead {
    margin-top: 40px;
}

.endimage {
    width: 219px;
    height: 219px;
    padding: 13.14px 21.9px 30.66px 21.9px;
}

.endbutton {
    font-size: 13px;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

/* end screen css ended */

/* Media queries for responsiveness */
@media (max-width: 1200px) {

    .btn-button,
    .btn-signin {
        margin-left: 20px;
        font-size: 18px;
    }
}

@media (min-width:481px) and (max-width:900px) {
    .mainbutton {
        border: none;
        font-family: "AvenirNextLTPro-Regular";
        font-size: 10px;
        font-weight: 600;
        padding: 0px 8px;
        line-height: 21.86px;
        text-align: center;
        color: #000000;
        border-radius: 5px;
        margin-left: 10px;
        gap: 8px;
        align-items: center;
        display: flex;
        box-shadow: -1px 2px 2px rgba(0, 0, 0, 0.3);


    }

    .btn-leftheader {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        gap: 8px;
        margin-left: -157px;
        justify-content: end;
    }

    .btn-leftheadersub {
        font-family: "AvenirNextLTPro-Regular";
        font-size: 12px;
        font-weight: 700;
        color: #000000;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 9px;
        justify-content: center;
        padding-right: 13px;
        position: relative;
        /* Establishes a positioning context for the pseudo-element */
        text-decoration: none;
    }
}

/* @media (max-width: 768px) {
     .headerone {
         flex-direction: column;
         align-items: flex-start;
         margin-left: 30px;
     }
 
     .btn-button, .btn-signin {
         margin-left: 0;
         margin-top: 10px;
         font-size: 16px;
     }
 
    
 } */
@media only screen and (max-width: 480px) {

    .containerheader {
        display: grid;
        grid-template-columns: 1fr;
        flex-direction: row;
        justify-content: flex-start;

    }

    .btn-leftheadersub {
        max-height: 26px;
        font-family: "AvenirNextLTPro-Regular";
        font-size: 13px;
        font-weight: 600;
        color: #000000;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        /* padding-right: 20px; */
    }

    .btn-leftheader {
        display: flex;
        flex-direction: row;
        align-items: left;
        cursor: pointer;
        gap: 4%;
        margin-left: -157px;
    }

    .btn-leftheadersub {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px
    }

}

/* header css ended */