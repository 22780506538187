ol{
    list-style-type: none;
} 
.heading{
    font-family: "RecoletaMedium";
    font-size: 1.4rem;
    font-weight: 600;
}
.subspan{
    font-family: "AvenirNextLTPro-Bold";
    font-size: 0.8rem;
    font-weight: 600;
}
strong{
    font-size: 0.9rem;
}
.para{
    font-family: "AvenirNextLTPro-Regular";
    font-size: 0.93rem;
    font-weight: 600;
}
.subpara{
    font-family: "AvenirNextLTPro-Regular";
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 2.5rem;
}
.subbutton {
    position: absolute;
    width: 531px;
    height: 48px;
    border-radius: 4px;
    box-shadow: -3px 3px 1px 0px rgba(0, 0, 0, 0.25);
    animation-name: moveInRight;
    animation-timing-function: ease-out;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    border: none;
    background-color: #000000;
    color: #FFFFFF;
}

@keyframes moveInRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
.subbutton:hover {
    opacity: 1;
}
