.footerbody {
    padding: 0px 20px;
    background-color: #F5F3EE !important;
}

.percentage {
    font-family: "AvenirNextLTPro-Regular";
    font-weight: 600;
    font-size: 0.8rem;
    cursor: pointer;
}

.icons {
    width: 1rem;
    height: 1rem;
    margin-bottom: 4px;
}

@media (width: 768px) {
    .col-md-8 {
        flex: 0 0 70%;
        max-width: 80%;
    }

    .percentage {
        font-size: 0.5rem;
    }

    .icons {
        width: 15px;
        height: 15px;
        margin-bottom: 4px;
    }
}