/* Notification.css */
.custom-notification {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: rgba(229, 231, 235, 0.75); /* Light gray with transparency */
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 10px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    width: 350px;
    z-index: 1000;
    animation: slide-in 0.5s ease-out forwards;
    gap: 10px;
  }
  
  .notification-header {
    display: flex;
    align-items: center;
  }
  
  .notification-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
  }

  .notification-body-conatiner {
    display: flex;
    flex-direction: column;
  }

  .notificationbody-text {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .notification-title {
    font-size: 14px;
    font-family: "AvenirNextLTPro-Bold";
    color: #333;
  }
  
  .notification-body {
    font-size: 16px;
    color: #666;
    font-family: "RecoletaBold";
  }

  .video-auido-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
    padding-top: 10px;
  }

  .notification-video-audio {
    width: 95px;
    padding: 5px;
    cursor: pointer;
    font-size: 14px;
    font-family: "RecoletaBold";
    background-color: green;
    color: #fff;
    border: none;
    border-radius: 16px;
  }

  .notification-video-audio-cancel {
    width: 95px;
    padding: 5px;
    cursor: pointer;
    font-size: 14px;
    font-family: "RecoletaBold";
    background-color: red;
    color: #fff;
    border: none;
    border-radius: 16px;
  }
  
  .notification-time {
    margin-left: auto;
    font-size: 12px;
    color: #aaa;
  }
  
  @keyframes slide-in {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  